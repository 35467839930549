import { StepComponentWithSlot } from "src/DataAssets/ConnectToDataSource/types"
import { StepContainer } from ".."
import { Flex } from "antd"
import { useContext } from "react"
import ConnectToDataSourceContext from "src/DataAssets/ConnectToDataSource/ConnectToDataSourceContext"

export function DummyLoad({ children }: StepComponentWithSlot) {
  /**
   * This component takes inspiration from the world of ham radio.
   * Much like an electrical system's dummy load, this component is a
   * brick of non-conductive material. It does nothing but provide a
   * place to stop progress and look at data.
   *
   * By comparison the EmptyStep component is highly conductive, because it
   * automatically sends you to the next step.
   */

  const { allTableNames, selectedTables, filteredTables } = useContext(ConnectToDataSourceContext)

  return children(
    {},
    <StepContainer>
      <Flex align="center" justify="center" vertical>
        <h1>Dummy Load</h1>
        <h2>Selected Tables</h2>
        <ul>
          {allTableNames.map((n) => (
            <li key={n}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {`${filteredTables.includes(n) ? "✅" : selectedTables.includes(n) ? "☑️" : "➖"}`} {n}
            </li>
          ))}
        </ul>
      </Flex>
      <p>50Ω</p>
    </StepContainer>,
  )
}
