import { Background } from "src/ui/OnboardingFlow/styles"
import SignupForm from "src/ui/SignupFlow/SignupForm"
import { SignupLoginModal } from "src/ui/LoginScreen/SignupLoginModal"
import { useEffect, useState } from "react"
import { useCurrentUser } from "src/common/hooks/useCurrentUser"
import { useNavigate } from "react-router-dom"

const SignupPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submittedEmail, setSubmittedEmail] = useState("")
  const { isAuthenticated } = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated, navigate])

  const handleSubmitSuccess = (email: string) => {
    setSubmittedEmail(email)
    setIsSubmitted(true)
  }

  return (
    <Background>
      <SignupLoginModal
        headerSection={isSubmitted ? "Please check your email" : "Sign up for GX Cloud"}
        secondaryHeaderSectionText={
          isSubmitted ? `Follow the link sent to ${submittedEmail} to finish signing up` : undefined
        }
        formsSection={isSubmitted ? null : <SignupForm onSubmitSuccess={handleSubmitSuccess} />}
      />
    </Background>
  )
}

export default SignupPage
