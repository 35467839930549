import { AggregateSuiteValidationResultQuery } from "src/api/graphql/graphql-operations"
import { StyledExpectationGroup, StyledList } from "src/DataAssets/AssetDetails/Validations/ValidationTab.styles"
import { FC, useMemo } from "react"
import { List, ListProps } from "antd"
import { ValidationResultCharts } from "src/Expectation/ValidationResultCharts"
import { AggregateSuiteValidationResult } from "src/api/graphql/graphql"
import { removeInvalidHtmlCharacters } from "src/common/utils/removeInvalidHtmlCharacters"
import { groupValidationResultsWithPlaceholderItems } from "src/DataAssets/AssetDetails/Validations/groupValidationResultsWithPlaceholderItems"
type ValidationResultChartListProps = {
  aggregateValidations: AggregateSuiteValidationResultQuery | undefined
  selectedAnchor: string
}

export const ValidationResultChartList = ({ aggregateValidations, selectedAnchor }: ValidationResultChartListProps) => {
  const groupNameExpectationIdResultArray = useMemo(
    () => groupValidationResultsWithPlaceholderItems(aggregateValidations),
    [aggregateValidations],
  )
  return (
    <>
      {groupNameExpectationIdResultArray.map(([groupName, validationResultGroup]) => {
        return (
          <div key={groupName}>
            <StyledExpectationGroup
              groupName={groupName}
              headerBottomBorder={false}
              $isSelected={selectedAnchor === removeInvalidHtmlCharacters(groupName.toLowerCase())}
            >
              <StyledList<FC<ListProps<AggregateSuiteValidationResult>>>
                itemLayout="vertical"
                dataSource={validationResultGroup}
                renderItem={(listValidationGroup: AggregateSuiteValidationResult) => (
                  <List.Item key={listValidationGroup?.expectationId}>
                    <ValidationResultCharts validationResultCharts={listValidationGroup?.results} />
                  </List.Item>
                )}
              />
            </StyledExpectationGroup>
          </div>
        )
      })}
    </>
  )
}
