import { useMemo } from "react"
import { useReactiveVar } from "@apollo/client"
import { currentOrgIdVar } from "src/organizations/index"
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "src/common/utils/local-storage"
import { OrganizationRole } from "src/api/graphql/graphql"
import { CurrentUserContext, useCurrentUser } from "src/common/hooks/useCurrentUser"
import { REACT_APP_API_URL, REACT_APP_APP_URL } from "src/common/env"

// RawOrganization comes from auth0
interface RawOrganization {
  organization_id: string
  name: string
  title: string
  slug: string
  data_plane_url: string
  user_attributes: {
    attribute_name: "organization_role"
    attribute_value: "organization_admin" | "editor" | "viewer"
  }[]
}

// Organization is an ergonomic form of RawOrganization
export interface Organization {
  id: string
  name: string
  slug: string
  title: string
  dataPlaneUrl: string
  // role is the authenticated user's role
  role: OrganizationRole
}

interface UseOrganizationsResult {
  // isLoading is true until org info is available
  isLoading: boolean
  // orgs includes all Organizations the user belongs to
  orgs: Organization[]
  // currentOrg is the Organization the user is currently viewing
  currentOrg?: Organization
  // setCurrentOrg sets the Organization for the user to view
  setCurrentOrg: (id: string) => void
}

const CACHE_KEY = "currentOrgId"

function getRole(attrs: RawOrganization["user_attributes"] = []): OrganizationRole {
  const roles = attrs.filter((a) => a.attribute_name === "organization_role")
  const hasAdmin = roles.some((a) => a.attribute_value === "organization_admin")
  const hasEditor = roles.some((a) => a.attribute_value === "editor")

  if (hasAdmin) {
    return "ORGANIZATION_ADMIN"
  }

  if (hasEditor) {
    return "EDITOR"
  }

  return "VIEWER" // default
}

function setCurrentOrg(id: string) {
  currentOrgIdVar(id) // update apollo cache
  setLocalStorageItem(CACHE_KEY, id) // update localstorage
}

function getResult(ctx: CurrentUserContext, currentOrgId: string): UseOrganizationsResult {
  const { user, isLoading, isAuthenticated, error, logout } = ctx

  if (error) {
    console.error("useOrganizations:", error)
  }

  if (isLoading) {
    return { isLoading: true, orgs: [], setCurrentOrg }
  }

  if (!user || !isAuthenticated || error) {
    return { isLoading: false, orgs: [], setCurrentOrg }
  }

  const rawOrgs: RawOrganization[] = user[`${REACT_APP_APP_URL}/organizations`] ?? []

  const orgs = rawOrgs.map((raw) => ({
    id: raw.organization_id,
    name: raw.name,
    title: raw.title || raw.name,
    slug: raw.slug,
    dataPlaneUrl: REACT_APP_API_URL,
    role: getRole(raw.user_attributes),
  }))

  const apolloOrgId = currentOrgId
  const isValidApolloOrgId = Boolean(apolloOrgId) && orgs.some((o) => o.id === apolloOrgId)

  const localOrgId = getLocalStorageItem(CACHE_KEY) ?? ""
  const isValidLocalOrgId = Boolean(localOrgId) && orgs.some((o) => o.id === localOrgId)

  let newCurrentOrgId = ""

  // case 0 - handle zero orgs
  if (orgs.length === 0) {
    removeLocalStorageItem(CACHE_KEY)
    logout()
    return { isLoading: false, orgs: [], setCurrentOrg }
  }

  // case 1 - use default
  if (!isValidApolloOrgId && !isValidLocalOrgId) {
    newCurrentOrgId = orgs[0].id
    setCurrentOrg(newCurrentOrgId) // use first org by default
  }

  // case 2 - use localstorage
  if (!isValidApolloOrgId && isValidLocalOrgId) {
    newCurrentOrgId = localOrgId
    setCurrentOrg(newCurrentOrgId)
  }

  // case 3 - prefer apollo
  if (isValidApolloOrgId && isValidLocalOrgId) {
    newCurrentOrgId = apolloOrgId
    if (apolloOrgId !== localOrgId) {
      setCurrentOrg(newCurrentOrgId)
    }
  }

  // case 4 - use apollo
  if (isValidApolloOrgId && !isValidLocalOrgId) {
    newCurrentOrgId = apolloOrgId
    setCurrentOrg(newCurrentOrgId)
  }

  return {
    isLoading: false,
    orgs,
    currentOrg: orgs.find((o) => o.id === newCurrentOrgId),
    setCurrentOrg,
  }
}

// useOrganizations provides organization info for the authenticated user
export function useOrganizations(): UseOrganizationsResult {
  const ctx = useCurrentUser()
  const currentOrgId = useReactiveVar(currentOrgIdVar)

  return useMemo(() => getResult(ctx, currentOrgId), [ctx, currentOrgId])
}
