import { Checkbox, Flex } from "antd"
import { Icon } from "src/ui/Icon"
import { Heading3 } from "src/ui/typography/Text/Text.tsx"
import { CheckboxChangeEvent } from "antd/es/checkbox"

type DataQualityCheck = {
  value: boolean
  setValue: (value: boolean) => void
}

type SelectDataQualityChecksProps = {
  schema: DataQualityCheck
  volume: DataQualityCheck
}

export const SelectDataQualityChecks = ({ schema, volume }: SelectDataQualityChecksProps) => {
  const checkboxStyle = { paddingLeft: "4px" }

  const onSchemaCheckboxChange = (e: CheckboxChangeEvent) => {
    schema.setValue(e.target.checked)
  }
  const onVolumeCheckboxChange = (e: CheckboxChangeEvent) => {
    volume.setValue(e.target.checked)
  }

  return (
    <Flex vertical gap="small">
      <Flex gap="small" align="center">
        <Icon name="bolt" />
        <Heading3>Autogenerate Expectations</Heading3>
      </Flex>
      <Checkbox style={checkboxStyle} defaultChecked={true} checked={schema.value} onChange={onSchemaCheckboxChange}>
        <b>Schema:</b> Detect column changes in selected Data Assets
      </Checkbox>
      <Checkbox style={checkboxStyle} defaultChecked={true} checked={volume.value} onChange={onVolumeCheckboxChange}>
        <b>Volume:</b> Detect non-increasing volume in selected Data Assets
      </Checkbox>
    </Flex>
  )
}
