import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import { graphql } from "src/api/graphql/gql"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { GenerateExpectationsButtonUI } from "src/GenerateExpectationsButton/GenerateExpectationsButtonUI"

interface GenerateExpectationsButtonProps {
  // assetID is the ID of the asset to generate expectations for
  assetID: string
}

export const GenerateExpectationsButtonFragmentDocument = graphql(`
  fragment GenerateExpectationsButtonFragment on AssetRef {
    id
    hasRunEchoes
    splitter {
      ... on WithID {
        id
      }
    }
    datasourceV2 {
      id
      type
    }
  }
`)

export const GenerateExpectationsButtonJobsFragmentDocument = graphql(`
  fragment GenerateExpectationsButtonJobsFragment on Query {
    jobs {
      id
      status
      jobType
      sourceResources {
        entityId
        entityType
      }
    }
  }
`)

export const CreateRunRdAgentJobDocument = graphql(`
  mutation CreateRunRdAgentJob($input: CreateRunRdAgentJobInput!) {
    createRunRdAgentJob(input: $input) {
      jobId
    }
  }
`)

export const GenerateExpectationsButtonDocument = graphql(`
  query GenerateExpectationsButton($id: UUID!) {
    dataAsset(id: $id) {
      ...GenerateExpectationsButtonFragment
    }
  }
`)

export const GenerateExpectationsButtonJobsDocument = graphql(`
  query GenerateExpectationsButtonJobs {
    ...GenerateExpectationsButtonJobsFragment
  }
`)

export function GenerateExpectationsButton(props: GenerateExpectationsButtonProps): JSX.Element | null {
  const { assetID } = props
  const apollo = useApolloClient()
  const featureEnabled = useIsFeatureEnabled("rdAssistantEnabled")
  const isAgentEnabled = useIsFeatureEnabled("forceEnableAgent")
  const isEditor = useRequireRole("EDITOR")
  const skipQueries = !assetID || !featureEnabled

  const { data: assetData, loading: queryLoading } = useQuery(GenerateExpectationsButtonDocument, {
    variables: { id: assetID },
    skip: skipQueries,
  })

  const { data: jobData } = useQuery(GenerateExpectationsButtonJobsDocument, {
    pollInterval: 500,
    skip: skipQueries,
  })

  const [createJob, { loading: mutationLoading }] = useMutation(CreateRunRdAgentJobDocument)

  if (!featureEnabled) {
    // short circuit if the feature flag is off
    return null
  }

  const handleClick = async (batchDefinitionId: string) => {
    await createJob({ variables: { input: { batchDefinitionId } } })
  }

  return (
    <GenerateExpectationsButtonUI
      loading={mutationLoading}
      disabled={queryLoading}
      onClick={handleClick}
      reFetchObservableQueries={apollo.reFetchObservableQueries}
      assetData={assetData?.dataAsset}
      jobData={jobData}
      isEditor={isEditor}
      isAgentEnabled={isAgentEnabled}
    />
  )
}
