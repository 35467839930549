import { type StepProps } from "antd"
import { Steps } from "src/ui/Steps/Steps.tsx"
import { useTheme } from "styled-components"
import { type ProgressBarProps } from "src/DataAssets/ConnectToDataSource/types.ts"

export const ProgressBar = ({ steps, currentStep }: ProgressBarProps) => {
  const theme = useTheme()
  const stepsItems = steps.reduce((acc, step) => [...acc, { title: step.title }], [] as StepProps[])
  const style = {
    marginTop: theme.spacing.vertical.xs,
    marginBottom: theme.spacing.vertical.s,
    marginLeft: theme.spacing.horizontal.s,
    marginRight: theme.spacing.horizontal.s,
  }
  return (
    <div style={style}>
      <Steps items={stepsItems} current={currentStep} />
    </div>
  )
}
