import { createContext, Dispatch, SetStateAction } from "react"
import { DatasourceWithRunsFragment } from "src/api/graphql/graphql-operations"
import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"

export type ConnectToDataSourceContext = {
  // step progression aids
  activeStep: number
  nextStep: () => void
  prevStep: () => void
  previouslyActiveStep: number
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>

  // all tables fetched from the data source
  allTableNames: string[]
  setAllTableNames: Dispatch<SetStateAction<string[]>>

  // tables the user has selected to add to coverage
  selectedTables: string[]
  setSelectedTables: Dispatch<SetStateAction<string[]>>

  // tables that have already been added
  filteredTables: string[]
  setFilteredTables: Dispatch<SetStateAction<string[]>>

  // data source
  dataSource?: DatasourceWithRunsFragment
  dataSourceType?: SupportedDataSource
}

const ConnectToDataSourceContext = createContext<ConnectToDataSourceContext>({
  activeStep: 0,
  nextStep: () => {},
  prevStep: () => {},
  previouslyActiveStep: 0,
  isLoading: true,
  setIsLoading: () => {},
  allTableNames: [],
  setAllTableNames: () => {},
  selectedTables: [],
  setSelectedTables: () => {},
  filteredTables: [],
  setFilteredTables: () => {},
  dataSource: undefined,
  dataSourceType: undefined,
})

export default ConnectToDataSourceContext
