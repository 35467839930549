import { Button, Form, Input } from "antd"
import styled from "styled-components"
import { useEffect, useState } from "react"
import { REACT_APP_API_URL } from "src/common/env"

interface SignupFormProps {
  onSubmitSuccess: (email: string) => void
}

const StyledForm = styled(Form)`
  width: 100%;
`

const StyledButton = styled(Button)`
  width: 100%;
  box-shadow: none;
`

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error.gxError};
  margin-top: ${({ theme }) => theme.spacing.vertical.xxs};
  text-align: center;
`

const SignupForm = ({ onSubmitSuccess }: SignupFormProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false)

  const values = Form.useWatch([], form)

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsSubmitDisabled(false))
      .catch(() => setIsSubmitDisabled(true))
  }, [form, values])

  const onSubmit = async () => {
    setLoading(true)
    setError("")
    const { email, organizationName } = form.getFieldsValue()

    try {
      const response = await fetch(`${REACT_APP_API_URL}/accounts/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ admin_email: email, organization_name: organizationName }),
      })

      if (!response.ok) {
        throw new Error("Failed to create account")
      }
      onSubmitSuccess(email)
    } catch (err) {
      setError("There was an error creating your account. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <StyledForm size="large" form={form} layout="vertical">
        <Form.Item
          name="email"
          label="Your email"
          rules={[{ required: true, type: "email", message: "Please enter a valid email" }]}
        >
          <Input type="email" aria-label="Email" />
        </Form.Item>
        <Form.Item
          name="organizationName"
          label="Organization name"
          rules={[{ required: true, min: 3, message: "Organization name must be at least 3 characters" }]}
        >
          <Input aria-label="Organization name" />
        </Form.Item>
      </StyledForm>
      <StyledButton type="primary" size="large" onClick={onSubmit} disabled={isSubmitDisabled} loading={loading}>
        Sign up
      </StyledButton>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  )
}

export default SignupForm
