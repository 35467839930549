import styled from "styled-components"
// barrel file exports to make it possible
// to use a single import path outside
// of this component tree
export * from "./ConnectToDataSourceContainer.tsx"
export * from "./ConnectToDataSourceContext.ts"
export * from "./steps"
export * from "./types"

export const StepContainer = styled.div`
  flex-grow: 0.95;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`
