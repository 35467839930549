import React, { useCallback, useMemo, useState } from "react"
import { Drawer, ScrollableFlex } from "src/ui/Drawer/Drawer"
import { AddAssetDrawerHeader } from "src/DataAssets/connect-to-data/DataCRUDDrawerHeaders"
import ConnectToDataSourceContext from "src/DataAssets/ConnectToDataSource/ConnectToDataSourceContext.ts"
import { Flex } from "antd"
import { ProgressBar } from "src/DataAssets/ConnectToDataSource/steps"
import { type ConnectToDataSourceProps } from "src/DataAssets/ConnectToDataSource/types"
import { DatasourceWithRunsFragment } from "src/api/graphql/graphql-operations"
import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { getAddressFromDataSource } from "src/DataAssets/connect-to-data/select-table-names/get-address-from-data-source"

export function ConnectToDataSourceContainer({ config }: ConnectToDataSourceProps) {
  const { steps, dataSourceType: _dataSourceType, dataSource: _dataSource, progressBar } = config
  const [activeStep, setActiveStep] = useState<number>(0)
  const [previouslyActiveStep, setPreviouslyActiveStep] = useState<number>(activeStep - 1) // used to determine user's current direction through the steps
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [dataSource] = useState<DatasourceWithRunsFragment | undefined>(_dataSource)
  const [dataSourceType] = useState<SupportedDataSource | undefined>(_dataSourceType)
  const [allTableNames, setAllTableNames] = useState<string[]>([])
  const [selectedTables, setSelectedTables] = useState<string[]>([])
  const [filteredTables, setFilteredTables] = useState<string[]>([])

  // Move backward and forward through steps and perform
  // any registered custom or component-level actions.
  // Also set `previouslyActiveStep` in case there are any
  // EmptyStep components present.
  const prevStep = useCallback(() => {
    setPreviouslyActiveStep(activeStep)
    const prevStepConfig = steps[activeStep].footerButtons.prev
    const prevActiveStep = activeStep - 1
    if (typeof prevStepConfig !== "string") {
      prevStepConfig.customAction && prevStepConfig.customAction(prevActiveStep)
    }
    setActiveStep(Math.max(prevActiveStep, 0))
  }, [activeStep, steps])

  const nextStep = useCallback(() => {
    setPreviouslyActiveStep(activeStep)
    const nextStepConfig = steps[activeStep].footerButtons.next
    const nextActiveStep = activeStep + 1
    if (typeof nextStepConfig !== "string") {
      nextStepConfig.customAction && nextStepConfig.customAction(nextActiveStep)
    }
    setActiveStep(Math.min(nextActiveStep, steps.length - 1))
  }, [activeStep, steps])

  // Get datasource address from config
  const dataSourceAddress = useMemo(() => {
    if (dataSource && "config" in dataSource) {
      const config = dataSource.config as string
      return getAddressFromDataSource(JSON.parse(config))
    }
  }, [dataSource])

  /**
   * The ConnectToDataSourceContainer component acts as a governing proxy between the
   * broader application and the discrete Step components defined in the ./steps directory.
   *
   * On the application side it exposes a configurable sequence of predefined Steps
   * with limited customizations available to each Step at config time.
   *
   * Within its internal context, it leverages the Render Prop pattern (see markup comment below)
   * to allow the slotted Step components to register their own (optional) actions
   * that run on the "back" and "next" buttons interactions.
   *
   * This covers use cases unique to both "users" and "developers" of these components.
   *
   * For example:
   * - as a "user" of these components, I may have some particular app state that I need to set
   *     when someone clicks a Back button. I can provide this state management via the
   *     footerButtons config for these steps without having to know how any particular
   *     Step is implemented
   *
   * - as a "developer" of these components, I know that my particular Step needs to make
   *     a few API calls when someone clicks the Next button, which in my use-case doubles
   *     as a Submit button. Using the Render Prop pattern allows me to hook my child
   *     component's API call into the parent Container's "next" button click and still adhere to
   *     any async/await needs presented by the API calls
   *
   */
  return (
    <ConnectToDataSourceContext.Provider
      value={{
        activeStep,
        nextStep,
        prevStep,
        previouslyActiveStep,
        isLoading,
        setIsLoading,
        dataSource,
        dataSourceType,
        selectedTables,
        setSelectedTables,
        filteredTables,
        setFilteredTables,
        allTableNames,
        setAllTableNames,
      }}
    >
      <ScrollableFlex vertical gap="middle">
        {progressBar && steps.length > 1 && <ProgressBar steps={steps} currentStep={activeStep} />}
        {dataSourceType && dataSource?.name && (
          <AddAssetDrawerHeader
            dataSourceType={dataSourceType}
            dataSourceName={dataSource.name}
            dataSourceAddress={dataSourceAddress}
          />
        )}
        <Flex vertical style={{ flexGrow: 1, height: "100%", width: "100%", overflowY: "hidden" }}>
          {steps.map(
            ({ title, bodyComponent: BodyComponent, footerButtons }, i) =>
              activeStep === i && (
                <React.Fragment key={title}>
                  <BodyComponent>
                    {/* Leverage Render Prop pattern here to provide an inversion of control for the child components' back & next actions. */}
                    {({ back, next, loading, disableProgress }, children) => (
                      <>
                        {children}
                        <Drawer.Footer>
                          <Drawer.FooterButton
                            onClick={async () => {
                              try {
                                back && (await back())
                                prevStep()
                              } catch (e) {
                                // eslint-disable-next-line no-console
                                console.log(e)
                              }
                            }}
                          >
                            {typeof footerButtons.prev === "string" ? footerButtons.prev : footerButtons.prev.label}
                          </Drawer.FooterButton>
                          <Drawer.FooterButton
                            type="primary"
                            loading={loading}
                            disabled={loading || disableProgress}
                            onClick={async () => {
                              try {
                                next && (await next())
                                nextStep()
                              } catch (e) {
                                // eslint-disable-next-line no-console
                                console.log(e)
                              }
                            }}
                          >
                            {typeof footerButtons.next === "string" ? footerButtons.next : footerButtons.next.label}
                          </Drawer.FooterButton>
                        </Drawer.Footer>
                      </>
                    )}
                  </BodyComponent>
                </React.Fragment>
              ),
          )}
        </Flex>
      </ScrollableFlex>
    </ConnectToDataSourceContext.Provider>
  )
}
