import { useQuery } from "@apollo/client"
import { App, Card, Flex } from "antd"
import { useCallback, useState } from "react"
import { graphql } from "src/api/graphql"
import { getLocalStorageItem, LocalStorageItemName, setLocalStorageItem } from "src/common/utils/local-storage"
import { Button } from "src/ui/Button/Button"
import { Icon } from "src/ui/Icon"
import { SalesDemoModalConfig } from "src/ui/Menu/SalesDemoModal"
import styled, { useTheme } from "styled-components"
import { useAnalytics } from "src/analytics/useAnalytics"

const BottomCenterFixedCard = styled(Card)`
  position: fixed;
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%);
  min-width: min(95vw, 600px);
  border: 1px solid ${({ theme }) => theme.colors.primaryColors.gxAccentMedium};
  & > .ant-card-body {
    /* your styles here */
    padding: ${({ theme }) => theme.spacing.scale.xxxs};
  }

  box-shadow:
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
`

const ButtonContainer = styled(Flex)`
  width: 205px;
`

const RequestSalesDemoBannerInfo = graphql(`
  query RequestSalesDemoBannerInfo {
    organization {
      id
      shouldPromoteTeamPlan
    }
    datasourcesV2 {
      id
      assets {
        id
      }
    }
  }
`)

export const BANNER_TEXT = "💡 Discover how to use GX Cloud at scale"
export const BUTTON_TEXT = "Request a demo"
export const LOCAL_STORAGE_KEY: LocalStorageItemName = "hasDismissedRequestDemoBanner"

export function RequestSalesDemoBanner() {
  const theme = useTheme()
  const { modal } = App.useApp()

  const { capture } = useAnalytics()
  const requestDemoOnClick = useCallback(() => {
    capture("request_sales_demo.floating_banner_clicked")
    modal.info({
      ...SalesDemoModalConfig,
      onCancel: () => {
        capture("request_sales_demo.modal_dismissed")
      },
    })
  }, [modal, capture])
  const { data } = useQuery(RequestSalesDemoBannerInfo)
  const [hasPreviouslyDismissed, setHasDismissed] = useState(getLocalStorageItem(LOCAL_STORAGE_KEY))

  const assetCount = data?.datasourcesV2?.reduce<number>((acc, datasource) => acc + datasource.assets.length, 0) ?? 0
  const notOnDeveloperPlan = Boolean(data?.organization?.shouldPromoteTeamPlan) === false
  const hasYetToAddAssets = assetCount === 0

  const shouldHideBanner = notOnDeveloperPlan || hasYetToAddAssets || hasPreviouslyDismissed

  if (shouldHideBanner) return null

  return (
    <BottomCenterFixedCard>
      <Flex justify="space-between" align="center">
        <span>{BANNER_TEXT}</span>
        <ButtonContainer justify="space-between" align="center">
          <Button
            icon={<Icon name="presentationPlay" small />}
            aria-label="request a demo"
            onClick={requestDemoOnClick}
            type="text"
          >
            {BUTTON_TEXT}
          </Button>
          <Button
            icon={<Icon name="close" small color={theme.colors.neutralColorPalette.blacks.colorTextTertiary} />}
            aria-label="close"
            onClick={() => {
              const now = new Date().toISOString()
              // using a piece of local react state allows us to hide after clicking to close
              // since setting local storage doesn't trigger an update
              setLocalStorageItem(LOCAL_STORAGE_KEY, now)
              setHasDismissed(now)
              capture("request_sales_demo.floating_banner_dismissed")
            }}
            type="text"
          />
        </ButtonContainer>
      </Flex>
    </BottomCenterFixedCard>
  )
}
