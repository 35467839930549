/**
 * This type is aimed where the graphql api is probably going.
 * Ideally this can be deleted eventually and we can get this type def from introspection.
 */

import { Job, Resource } from "src/api/graphql/graphql"

export type JobUnion =
  | GenerateExpectationSuiteJob
  | CreateTestDatasourceJob
  | ValidateDataJob
  | UnknownJob
  | CreateMetricsListJob
  | CreateListTableNamesJob
  | GenerateDataQualityCheckExpectationsJob
  | RunRDAgentJob

type JobBase = Pick<Job, "errorMessage" | "jobError" | "id" | "status" | "timeQueued"> & { jobType: JobType }
export type JobType =
  | "CreateTestDatasourceJob"
  | "GenerateExpectationSuiteJob"
  | "ValidateDataJob"
  | "Unknown"
  | "CreateMetricsListJob"
  | "CreateListTableNamesJob"
  | "GenerateDataQualityCheckExpectationsJob"
  | "RunRDAgentJob"

export type CreateTestDatasourceJob = JobBase &
  Pick<Job, "tableNames"> & {
    jobType: "CreateTestDatasourceJob"
  }

export type CreateListTableNamesJob = JobBase &
  Pick<Job, "tableNames"> & {
    jobType: "CreateListTableNamesJob"
  }

export type CreateMetricsListJob = JobBase & {
  jobType: "CreateMetricsListJob"
  dataAssetId?: string
}

export type GenerateExpectationSuiteJob = JobBase & {
  jobType: "GenerateExpectationSuiteJob"
  dataAssetId: string

  createdCheckpointId?: string
  createdExpectationSuiteId?: string
}

export type ValidateDataJob = JobBase & {
  jobType: "ValidateDataJob"
  checkpointId: string

  createdValidationResultId?: string
}

export type GenerateDataQualityCheckExpectationsJob = JobBase & {
  jobType: "GenerateDataQualityCheckExpectationsJob"
  sourceResources: Resource[]
  createdResources: Resource[]
}

export type RunRDAgentJob = JobBase & {
  jobType: "RunRDAgentJob"
  sourceResources: Resource[]
  createdResources: Resource[]
}

export type UnknownJob = JobBase & {
  jobType: "Unknown"
}

export function mapJobtoUnionType(job: Omit<Job, "jobType">): JobUnion {
  // converts a generic Job with a resource into a more specific JobUnion type with a jobType with respective resource ids
  const dataAssetSourceResource = job.sourceResources.find(({ entityType }) => entityType === "DataAsset")
  const datasourceSourceResource = job.sourceResources.find(({ entityType }) => entityType === "Datasource")
  const checkpointSourceResource = job.sourceResources.find(({ entityType }) => entityType === "Checkpoint")
  const draftConfigSourceResource = job.sourceResources.find(({ entityType }) => entityType === "DraftConfig")
  const hasNoSourceResources = job.sourceResources.length === 0
  if ("jobType" in job && job.jobType === "GENERATE_DATA_QUALITY_CHECK_EXPECTATIONS_REQUEST_RECEIVED") {
    return {
      ...job,
      jobType: "GenerateDataQualityCheckExpectationsJob",
      sourceResources: job.sourceResources ?? [],
      createdResources: job.createdResources ?? [],
    }
  }
  if ("jobType" in job && job.jobType === "RUN_RD_AGENT_REQUEST") {
    return {
      ...job,
      jobType: "RunRDAgentJob",
      sourceResources: job.sourceResources ?? [],
      createdResources: job.createdResources ?? [],
    }
  }
  if (dataAssetSourceResource && datasourceSourceResource) {
    const { sourceResources, createdResources, ...rest } = job
    const createdExpectationSuiteId = job.sourceResources.find(
      ({ entityType }) => entityType === "ExpectationSuite",
    )?.entityId
    const createdCheckpointId = job.sourceResources.find(({ entityType }) => entityType === "Checkpoint")?.entityId

    return {
      ...rest,
      jobType: "GenerateExpectationSuiteJob",
      dataAssetId: dataAssetSourceResource.entityId,
      createdExpectationSuiteId: createdExpectationSuiteId,
      createdCheckpointId: createdCheckpointId,
    }
  }
  if (dataAssetSourceResource && !datasourceSourceResource) {
    const { sourceResources, createdResources, ...rest } = job
    const dataAssetId = job.sourceResources.find(({ entityType }) => entityType === "DataAsset")?.entityId
    return {
      ...rest,
      jobType: "CreateMetricsListJob",
      dataAssetId: dataAssetId,
    }
  }
  if (checkpointSourceResource) {
    const { sourceResources, createdResources, ...rest } = job
    const createdValidationResultId = job.sourceResources.find(
      ({ entityType }) => entityType === "SuiteValidationResult",
    )?.entityId
    return {
      ...rest,
      jobType: "ValidateDataJob",
      checkpointId: checkpointSourceResource.entityId,
      createdValidationResultId,
    }
  }
  if (hasNoSourceResources || draftConfigSourceResource) {
    return {
      ...job,
      jobType: "CreateTestDatasourceJob",
    }
  }
  if (datasourceSourceResource) {
    return {
      ...job,
      jobType: "CreateListTableNamesJob",
    }
  }
  return {
    ...job,
    jobType: "Unknown",
  }
}
