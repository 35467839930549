import { MainContent } from "src/layout/MainContent"
import styled from "styled-components"
import { GetStarted } from "src/ui/GetStarted/GetStarted"
import { DataSourceChoice, NewAssetDrawer } from "src/DataAssets/connect-to-data/NewAssetDrawer"
import { NewDemoAssetDrawer } from "src/DataAssets/connect-to-data/demo-data/NewDemoAssetDrawer"
import { useCallback, useState } from "react"

import { Heading2, LG } from "src/ui/typography/Text/Text"
import { App, Col, Row } from "antd"
import { theme } from "src/ui/themes/theme"
import { Icon } from "src/ui/Icon"
import { SalesDemoModalConfig } from "src/ui/Menu/SalesDemoModal"
import { DataSourceCards, DataSourceCard } from "src/DataAssets/connect-to-data/PickNewOrExistingDataSource"
import { useAnalytics } from "src/analytics/useAnalytics"

const CenteredContainer = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`

export function DataAssetsEmptyScreen({ isEditorOrAdmin }: Readonly<{ isEditorOrAdmin: boolean }>) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isDemoDataDrawerOpen, setIsDemoDataDrawerOpen] = useState(false)
  const [dataSourceChoice, setDataSourceChoice] = useState<DataSourceChoice>()

  const { modal } = App.useApp()
  const { capture } = useAnalytics()

  const handleOpenSalesDemoModal = useCallback(() => {
    capture("request_sales_demo.datasource_card_clicked")
    modal.info(SalesDemoModalConfig)
  }, [modal, capture])

  const onDatasourceSelect = (choice: DataSourceChoice) => {
    setIsDrawerOpen(true)
    setDataSourceChoice(choice)
  }

  const emptyStateTitle = "Connect to a data source to get started"

  const showDataAssetCards = isEditorOrAdmin

  return (
    <MainContent>
      <CenteredContainer>
        <GetStarted large title={emptyStateTitle}>
          {showDataAssetCards && (
            <>
              <DataSourceCards large setChoice={onDatasourceSelect} />

              <Heading2 style={{ marginTop: theme.spacing.vertical.s }}>Not ready to connect to your data?</Heading2>
              <Row gutter={16}>
                <Col span={12}>
                  <DataSourceCard
                    large
                    type="demo"
                    onClick={() => setIsDemoDataDrawerOpen(true)}
                    image={<Icon name="flask" />}
                    text="Use demo data"
                  />
                </Col>
                <Col span={12}>
                  <DataSourceCard
                    large
                    type="demo"
                    onClick={handleOpenSalesDemoModal}
                    image={<Icon name="playCircle" />}
                    text="Request a demo"
                  />
                </Col>
              </Row>

              <NewAssetDrawer
                dataSourceChoiceFromEmptyState={dataSourceChoice}
                isOpen={isDrawerOpen}
                setIsOpen={setIsDrawerOpen}
              />
              <NewDemoAssetDrawer isOpen={isDemoDataDrawerOpen} setIsOpen={setIsDemoDataDrawerOpen} />
            </>
          )}
          {!isEditorOrAdmin && <LG>Only Editors and Admins can add Data Assets.</LG>}
        </GetStarted>
      </CenteredContainer>
    </MainContent>
  )
}
