import { Form } from "antd"
import { useContext, useMemo, useState } from "react"
import { JsonForm } from "src/DataAssets/connect-to-data/JsonForm"
import {
  DataSourceUISchemaMap,
  defaultValues,
  getDataSourceJsonSchemaMap,
  getUISchemaRegistryEntries,
} from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import ConnectToDataSourceContext from "src/DataAssets/ConnectToDataSource/ConnectToDataSourceContext"
import { GroupRendererRegistryEntry } from "src/jsonforms/layouts/GroupRenderer"
import { StepComponentWithSlot } from "src/DataAssets/ConnectToDataSource/types"
import { StepContainer } from ".."

export function EnterCredentialsStep({ children }: StepComponentWithSlot) {
  const { dataSourceType } = useContext(ConnectToDataSourceContext)
  const [form] = Form.useForm()
  const initialData = useMemo(() => (dataSourceType ? defaultValues[dataSourceType] : {}), [dataSourceType])
  const [formData] = useState<Record<string, unknown>>(initialData)

  const jsonSchema = useMemo(
    () => (dataSourceType ? getDataSourceJsonSchemaMap()[dataSourceType] : undefined),
    [dataSourceType],
  )

  const schemaRegistryEntries = useMemo(
    () => (dataSourceType ? getUISchemaRegistryEntries(dataSourceType) : undefined),
    [dataSourceType],
  )

  // Early return if we don't have a dataSourceType
  // (this makes both TS & React happy, as long as there's no hooks after this point)
  if (!dataSourceType) {
    return null
  }

  const [uiSchema] = DataSourceUISchemaMap[dataSourceType]

  return children(
    {},
    <StepContainer>
      <Form form={form} layout="vertical">
        <JsonForm
          jsonSchema={jsonSchema}
          uiSchema={uiSchema}
          uiSchemaRegistryEntries={schemaRegistryEntries}
          customRendererRegistryEntries={[GroupRendererRegistryEntry]}
          data={formData}
          updateData={() => {}}
        />
        {/* {errorMessage && <AlertBanner />} */}
      </Form>
    </StepContainer>,
  )
}
