/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Expectation_ValidationResultFragment } from "src/api/graphql/graphql-operations"
import { VisualizationSpec } from "react-vega"
import { Config } from "vega-lite"
import { Chart } from "src/ui/Chart/Chart"
import { ChartConfig, failureIconPath, successIconPath, vegaLiteSchema } from "src/ui/Chart/ChartConfig"
import { useTheme } from "styled-components"
import {
  addIndexToData,
  getLabelExpression,
  getObservedValueFromExpectationValidationResult,
  getObservedValueTooltip,
  getTickSizeExpression,
  getXAxisValues,
  sortData,
  CastRenderedContentValueResult,
  RenderedContentValue,
  getBatchIdFromKwargs,
} from "src/Expectation/Charts/ChartUtils"
import { SplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"

export const DefaultChart = (props: {
  validationResultCharts: Expectation_ValidationResultFragment[]
  splitter: SplitterData
}) => {
  const sortedData = getDataForExpectations(props.validationResultCharts, props.splitter)
  addIndexToData(sortedData)

  const xAxisValues = getXAxisValues(sortedData)
  const labelExpression = getLabelExpression(sortedData)
  const tickSizeExpression = getTickSizeExpression(sortedData)

  const theme = useTheme()
  const config: Config = ChartConfig()
  const spec: VisualizationSpec = {
    $schema: vegaLiteSchema,
    width: "container",
    height: 60,
    padding: { left: 42 },
    autosize: {
      type: "fit",
      resize: true,
      contains: "padding",
    },
    config: config,
    data: sortedData,
    mark: {
      type: "point",
      filled: true,
      xOffset: -12,
      yOffset: -14,
    },
    encoding: {
      x: {
        field: "index",
        type: "quantitative",
        axis: {
          title: "Runs",
          values: xAxisValues,
          labelExpr: labelExpression,
          tickSize: { expr: tickSizeExpression },
        },
      },
      shape: {
        field: "success",
        type: "nominal",
        scale: {
          domain: [true, false],
          range: [successIconPath, failureIconPath],
        },
        legend: null,
      },
      color: {
        field: "success",
        type: "nominal",
        legend: null,
        condition: [
          { test: "datum['success'] === true", value: theme.colors.success.gxSuccess },
          { test: "datum['success'] === false", value: theme.colors.error.gxError },
          { test: "datum['success'] === null", value: theme.colors.neutralColorPalette.whites.white },
        ],
      },
      size: {
        condition: { test: "datum['success'] === null", value: 0 },
        value: 4,
      },
      tooltip: [
        {
          title: "Success",
          field: "success",
          type: "nominal",
        },
        {
          title: "Run time",
          field: "runtime",
          type: "temporal",
          format: "%m/%d/%Y - %H:%M:%S %p",
        },
        {
          title: "Batch interval",
          field: "batchInterval",
          type: "nominal",
        },
        {
          title: "Batch column",
          field: "batchColumn",
          type: "nominal",
        },
        {
          title: "Batch name",
          field: "batchId",
          type: "nominal",
        },
        {
          title: "Observed value",
          field: "observedValueTooltip",
          type: "nominal",
        },
      ],
    },
  }
  return <Chart spec={spec} />
}

type DefaultChartData = {
  success: boolean | null | undefined
  runtime: string
  observedValue: RenderedContentValue
  observedValueTooltip: RenderedContentValue
  batchId: string
  batchInterval: string
  batchColumn: string
}
export function getDataForExpectations(
  validationChartResults: Expectation_ValidationResultFragment[],
  splitter: SplitterData,
) {
  const values: DefaultChartData[] = validationChartResults.map((expectationValidationResult) => {
    const fallbackType = "string"
    const observedValue: CastRenderedContentValueResult = getObservedValueFromExpectationValidationResult(
      expectationValidationResult,
      fallbackType,
    )
    const observedValueTooltip: RenderedContentValue = getObservedValueTooltip(observedValue)
    const batchId = getBatchIdFromKwargs(expectationValidationResult)
    return {
      success: expectationValidationResult.success,
      runtime: String(expectationValidationResult.runTime),
      batchInterval: splitter.splitter.length > 0 ? splitter.splitter : "N/A",
      batchColumn: splitter.splitterColumns ?? "N/A",
      batchId: batchId,
      observedValue: observedValue.value,
      observedValueTooltip: observedValueTooltip,
    }
  })

  const sortedData = sortData(values)

  // add data points to beginning and end for alignment with other chart types
  sortedData.values.unshift({
    success: null,
    runtime: "",
    observedValue: undefined,
    observedValueTooltip: "",
    batchId: "",
    batchInterval: "",
    batchColumn: "",
  })
  sortedData.values.push({
    success: null,
    runtime: "",
    observedValue: undefined,
    observedValueTooltip: "",
    batchId: "",
    batchInterval: "",
    batchColumn: "",
  })

  return sortedData
}
