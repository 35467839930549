/* eslint-disable react-refresh/only-export-components */ // FIXME
import styled from "styled-components"
import { useCallback, useState } from "react"
import { Icon } from "src/ui/Icon"
import { App, Badge, Flex, MenuProps, Tag } from "antd"
import { useQuery } from "@apollo/client"

import { submenuPopupClassName } from "src/ui/Menu/menuCSS"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { LOGS_PATH_NAME } from "src/GXAgent/GXAgent"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"
import { ItemType, MenuItemType, SubMenuType } from "antd/es/menu/hooks/useItems"
import { useAgentStatus } from "src/common/hooks/useAgentStatus"
import { theme } from "src/ui/themes/theme"
import { DOCS_URL } from "src/common/config"
import { graphql } from "src/api/graphql"
import { SupportModalConfig } from "src/ui/Menu/SupportModal"
import { NODE_ENV } from "src/common/env"
import { NewDemoAssetDrawer } from "src/DataAssets/connect-to-data/demo-data/NewDemoAssetDrawer"
import { UnstyledLink } from "src/ui/typography/Link/Link.tsx"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled.ts"
import { SalesDemoModalConfig } from "src/ui/Menu/SalesDemoModal.tsx"
import { useAnalytics } from "src/analytics/useAnalytics"

type MenuItem = Required<MenuProps>["items"][number]
type AgentIconType = typeof ICON_NAME_INITIALIZING | typeof ICON_NAME_ACTIVE | typeof ICON_NAME_INACTIVE

export const SETTINGS_MENU_KEY = "settings"
const MENU_ICON_SIZE = "20px"
const ICON_NAME_ACTIVE = "cloudCheck"
const ICON_NAME_INACTIVE = "cloudTimes"
const ICON_NAME_INITIALIZING = "cloudQuestion"
const ICON_NAME_LOGS = "history"

const StyledLink = styled(UnstyledLink)`
  && {
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }
  }
`

const getLogs = (disabled: boolean) => {
  return {
    key: "logs",
    disabled,
    label: (
      <StyledLink to={LOGS_PATH_NAME} disabled={disabled}>
        Logs
      </StyledLink>
    ),
    icon: <Icon name={ICON_NAME_LOGS} size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
  }
}

const getAgentMenuItem = (isAgentActive: boolean | undefined) => {
  let agentStatusText = "Initializing"
  if (isAgentActive !== undefined) {
    agentStatusText = isAgentActive ? "Active" : "Inactive"
  }

  let agentStatusBadgeColor: string = theme.colors.status.gxBadgeInitializing
  if (isAgentActive !== undefined) {
    agentStatusBadgeColor = isAgentActive ? theme.colors.status.gxBadgeActive : theme.colors.status.gxBadgeInactive
  }

  let iconName: AgentIconType = ICON_NAME_INITIALIZING
  let toolTipEnabled = false
  if (isAgentActive !== undefined) {
    if (isAgentActive) {
      iconName = ICON_NAME_ACTIVE
    } else {
      iconName = ICON_NAME_INACTIVE
      toolTipEnabled = true
    }
  }
  const agentTag = (
    <Flex>
      <Tag color={agentStatusBadgeColor}>{agentStatusText} Agent</Tag>
    </Flex>
  )
  const label = toolTipEnabled ? agentTag : agentTag

  return {
    key: "agentStatus",
    label: label,
    icon: (
      <Badge color={agentStatusBadgeColor} dot offset={[-2, 4]} style={{ boxShadow: "none" }}>
        <Icon
          name={iconName}
          color={theme.colors.neutralColorPalette.whites.white}
          size={MENU_ICON_SIZE}
          className="anticon anticon-desktop ant-menu-item-icon"
        />
      </Badge>
    ),
    disabled: true,
    // This menu item is not clickable
    style: { cursor: "default" },
  } satisfies ItemType
}

const settingsSubMenu = (withUsers: boolean, withTokens: boolean, onTitleClick: SubMenuType["onTitleClick"]) => {
  const children: MenuItemType[] = []

  if (withUsers) {
    children.push(users)
  }
  if (withTokens) {
    children.push(tokens)
  }

  return {
    key: SETTINGS_MENU_KEY,
    label: "Settings",
    icon: <Icon name="settings" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
    children,
    popupClassName: submenuPopupClassName,
    onTitleClick,
  }
}

const users = {
  key: "users",
  label: <StyledLink to="users">Users</StyledLink>,
}

const tokens = {
  key: "tokens",
  label: <StyledLink to="tokens">Tokens</StyledLink>,
}

const docs = {
  key: "documentation",
  label: (
    <StyledLink to={DOCS_URL} target="_blank">
      Documentation
    </StyledLink>
  ),
  icon: <Icon name="file" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
}

const requestDemoMenuItem = {
  key: "requestDemo",
  icon: <Icon name="presentationPlay" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
  label: "Request a demo",
}

const supportMenuItem = {
  key: "support",
  title: "Support",
  icon: <Icon name="questionCircle" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
  label: "Support",
}

const ff = {
  key: "_ff",
  title: "Feature Flags",
  icon: <Icon name="toggleOn" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
  label: <StyledLink to="_ff">Feature Flags</StyledLink>,
}

interface useMenuItemsProps {
  toggleSettings: () => void
}

interface useMenuItemsReturnProps {
  topMenuItems: MenuItem[]
  bottomMenuItems: MenuItem[]
}

export const AllDataSourcesDocument = graphql(`
  query allDatasources {
    datasourcesV2 {
      id
    }
  }
`)

function useMenuItems({ toggleSettings }: useMenuItemsProps): useMenuItemsReturnProps {
  const { modal } = App.useApp()
  const { capture } = useAnalytics()
  const requestDemoMenuItemOnClick = useCallback(() => {
    capture("request_sales_demo.menu_item_clicked")
    modal.info({
      ...SalesDemoModalConfig,
      onCancel: () => {
        capture("request_sales_demo.modal_dismissed")
      },
    })
  }, [modal, capture])

  const supportMenuItemOnClick = useCallback(() => {
    modal.info(SupportModalConfig)
  }, [modal])

  const showUsers = useRequireRole("ORGANIZATION_ADMIN")
  const showTokens = useRequireRole("EDITOR")
  const isViewer = useRequireRole("VIEWER", true)
  const isGXAgentEnabled = useIsGXAgentEnabled()
  const isTeamPlanUser = useIsFeatureEnabled("teamPlanEnabled")
  const isAgentActive = useAgentStatus()
  const { data: dataSourcesData, loading: dataSourcesLoading } = useQuery(AllDataSourcesDocument)
  const isDataSourcesEmpty = !dataSourcesLoading && dataSourcesData?.datasourcesV2.length === 0
  const [showDemoDataDrawer, setShowDemoDataDrawer] = useState(false)

  const sd = {
    key: "_sd",
    title: "Dev: Demo Data",
    icon: <Icon name="database" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
    label: (
      <>
        <div>Dev: Demo Data</div>
        <NewDemoAssetDrawer isOpen={showDemoDataDrawer} setIsOpen={setShowDemoDataDrawer} />
      </>
    ),
    onClick: () => {
      if (!showDemoDataDrawer) {
        setShowDemoDataDrawer(true)
      }
    },
  }

  const assets = {
    key: "data-assets",
    label: <StyledLink to="data-assets">Data Assets</StyledLink>,
    icon: <Icon name="database" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
  }

  return {
    topMenuItems: [
      assets,
      getLogs(isDataSourcesEmpty || dataSourcesLoading),
      ...(isViewer ? [] : [settingsSubMenu(showUsers, showTokens, toggleSettings)]),
    ],
    bottomMenuItems: [
      ...(isGXAgentEnabled ? [getAgentMenuItem(isAgentActive)] : []),
      ...(isTeamPlanUser ? [] : [{ ...requestDemoMenuItem, onClick: requestDemoMenuItemOnClick }]),
      docs,
      { ...supportMenuItem, onClick: supportMenuItemOnClick },
      ...(NODE_ENV === "development" ? [ff] : []),
      ...(NODE_ENV === "development" ? [sd] : []),
    ],
  }
}

export { useMenuItems }
