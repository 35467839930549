import { useEffect } from "react"
import { LoadingState } from "src/ui/LoadingState"
import { useCurrentUser } from "src/common/hooks/useCurrentUser"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { useNavigate } from "react-router-dom"
import { graphql } from "src/api/graphql/gql"
import { useQuery, useReactiveVar } from "@apollo/client"
import { currentOrgIdVar } from "src/organizations"

export const OrganizationDocument = graphql(`
  query Organization_DefaultRedirect($id: ID!) {
    organization(id: $id) {
      id
      slug
      hasDefaultName
      onboardingSurveySeen
    }
  }
`)

export const DefaultRedirect = () => {
  const navigate = useNavigate()
  const { isAuthenticated, isLoading: authLoading } = useCurrentUser()

  const currentOrgId = useReactiveVar(currentOrgIdVar)
  const { data, loading: isLoadingQueryOrganization } = useQuery(OrganizationDocument, {
    variables: { id: currentOrgId },
    skip: !currentOrgId,
  })
  const organization = data?.organization

  const isLoading = authLoading || (isAuthenticated && isLoadingQueryOrganization)
  const isUseOnboardingSurveySeenEnabled = useIsFeatureEnabled("useOnboardingSurveySeenEnabled")

  useEffect(() => {
    function getRedirectRoute() {
      if (!organization?.slug) {
        return navigate("/")
      }

      const showSurvey = isUseOnboardingSurveySeenEnabled
        ? !organization?.onboardingSurveySeen
        : organization?.hasDefaultName
      if (showSurvey) {
        return navigate(`/organizations/${organization.slug}/welcome`)
      }

      return navigate(`/organizations/${organization.slug}/data-assets`)
    }

    if (!isLoading && isAuthenticated) {
      getRedirectRoute()
    }
  }, [
    isAuthenticated,
    isUseOnboardingSurveySeenEnabled,
    isLoading,
    organization?.slug,
    organization?.hasDefaultName,
    organization?.onboardingSurveySeen,
    navigate,
  ])

  return <LoadingState loading={isLoading} />
}
