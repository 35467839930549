import { useCallback, useState, useEffect } from "react"
import stringify from "json-stable-stringify"
import { AlertProps, Flex, Form, message } from "antd"
import { isEqual } from "lodash-es"
import { ApolloError, useMutation, useReactiveVar } from "@apollo/client"
import { JsonForm } from "src/DataAssets/connect-to-data/JsonForm"
import { DatasourcesDocument, DatasourcesQuery } from "src/api/graphql/graphql-operations"
import { AddAssetDrawerHeader, LogoAndLinkHeader } from "src/DataAssets/connect-to-data/DataCRUDDrawerHeaders"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import {
  getDataSourceJsonSchemaMap,
  DataSourceUISchemaMap,
  SupportedDataSource,
  getUISchemaRegistryEntries,
} from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { useLazyAgentStatus } from "src/common/hooks/useAgentStatus"
import {
  MESSAGE_DURATION_SECONDS,
  AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT,
  AUTOGENERATE_EXPECTATIONS_LIMIT_ERROR,
} from "src/common/config"
import { AssetDrawerFooter } from "src/DataAssets/connect-to-data/AssetDrawerFooter"
import { ScrollableFlex } from "src/ui/Drawer/Drawer"
import { useAssetCreationJobStatus } from "src/DataAssets/connect-to-data/useAssetCreationJobStatus"
import { graphql } from "src/api/graphql/gql"
import { SelectTableNames } from "src/DataAssets/connect-to-data/select-table-names/SelectTableNames"
import { getAddressFromDataSource } from "src/DataAssets/connect-to-data/select-table-names/get-address-from-data-source"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"
import { GroupRendererRegistryEntry } from "src/jsonforms/layouts/GroupRenderer"
import { SelectAutogenerateExpectations } from "src/DataAssets/connect-to-data/SelectAutogenerateExpectations.tsx"
import { isEnableAgentRequestPendingVar, RequestAgentAlert } from "src/DataAssets/connect-to-data/RequestAgentAlert"
import { SelectDataQualityChecks } from "src/DataAssets/connect-to-data/SelectDataQualityChecks"
import { DataQualityIssues } from "src/api/graphql/graphql"

export const CreateTestDatasourceConfigJobDocument = graphql(`
  mutation CreateTestDatasourceConfigJob($config: JSONString!) {
    createTestDatasourceJob(config: $config) {
      jobId
    }
  }
`)

export const CreateDataSourceWithTableNamesDocument = graphql(`
  mutation CreateDataSourceWithTableNames($input: CreateDataSourceAndAssetsInput!) {
    createDataSourceAndAssets(input: $input) {
      datasourceV2 {
        id
        name
        type
        assets {
          id
          name
          createdAt
        }
      }
    }
  }
`)

type AddAssetToNewDataSourceProps = {
  dataSourceType: SupportedDataSource
  onBack: () => void
  onFinish: () => void
  data: Record<string, unknown>
}

export type TestConnectionMutationErrorProps = Partial<AlertProps>

export function AddAssetToNewDataSource({
  data: initialData,
  onBack,
  onFinish,
  dataSourceType,
}: AddAssetToNewDataSourceProps) {
  const isColumnChangeDetectionEnabled = useIsFeatureEnabled("columnChangeDetection")
  const isVolumeChangeDetectionEnabled = useIsFeatureEnabled("enableVolumeChangeDetection")
  const [schemaChangeDetection, setSchemaChangeDetection] = useState<boolean>(true)
  const [volumeChangeDetection, setVolumeChangeDetection] = useState<boolean>(true)
  const availableDataQualityIssues = {
    ...(isVolumeChangeDetectionEnabled && { VOLUME: volumeChangeDetection }),
    ...(isColumnChangeDetectionEnabled && { SCHEMA: schemaChangeDetection }),
  } satisfies Partial<Record<DataQualityIssues, boolean>>
  const getTypedKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>
  const selectedDataQualityIssues = getTypedKeys(availableDataQualityIssues).filter(
    (key) => availableDataQualityIssues[key],
  ) satisfies DataQualityIssues[]

  const jsonSchema = getDataSourceJsonSchemaMap()[dataSourceType]
  const [page1UISchema] = DataSourceUISchemaMap[dataSourceType]
  const [data, setData] = useState<Record<string, unknown>>(initialData)
  const [page, setPage] = useState<1 | 2>(1)
  const [page2Error, setPage2Error] = useState<string>()
  const [tableNames, setTableNames] = useState<string[]>()
  const [form] = Form.useForm()
  const [jobIsPending, setJobIsPending] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<null | AlertProps>(null)
  const [selectedTableNames, setSelectedTableNames] = useState<string[]>([])
  const dataSourceAddress = getAddressFromDataSource(data)
  const isEnableAgentRequestPending = useReactiveVar(isEnableAgentRequestPendingVar)

  const [createJob, { data: testConnectionData, reset: resetCreateJobMutation }] = useMutation(
    CreateTestDatasourceConfigJobDocument,
    {
      onError: (err: ApolloError) => handleTestConnectionMutationError({ description: err.message, message: err.name }),
      variables: { config: JSON.stringify({ ...data, assets: [] }) },
    },
  )
  const handleTestConnectionMutationError: ({ description, message, type }: TestConnectionMutationErrorProps) => void =
    useCallback(
      ({ description, message, type }) => {
        setErrorMessage({ description, message: message ?? null, type: type ?? "error" })
        setJobIsPending(false)
        resetCreateJobMutation()
      },
      [resetCreateJobMutation],
    )
  const onChange = useCallback(
    (newData: Record<string, unknown>) => {
      if (!isEqual(data, newData)) {
        setData(newData)
      }
    },
    [data],
  )

  const [saveDatasource, { reset: resetSaveDatasourceMutation, loading: saveInProgress }] = useMutation(
    CreateDataSourceWithTableNamesDocument,
    {
      update: (cache, result) => {
        const datasource = result.data?.createDataSourceAndAssets?.datasourceV2
        cache.updateQuery({ query: DatasourcesDocument }, (cachedQuery: DatasourcesQuery | null) => {
          if (!datasource || !cachedQuery) {
            return undefined
          }
          return {
            ...cachedQuery,
            datasourcesV2: [...cachedQuery.datasourcesV2, datasource],
          }
        })
      },
      onError: (error: ApolloError) => {
        setPage2Error(error.message)
      },
      onCompleted: (result) => {
        const assets = result.createDataSourceAndAssets?.datasourceV2.assets
        message.success(
          // TODO: this shouldn't be ephemeral & we should prompt users to filter to show only these assets
          assets?.length === 1 ? `1 Data Asset added` : `${assets?.length} Data Assets added`,
          MESSAGE_DURATION_SECONDS,
        )
        onClose()
        onFinish()
      },
    },
  )
  const onClose = useCallback(() => {
    setPage(1)
    resetCreateJobMutation()
    resetSaveDatasourceMutation()
    setData({})
    form.resetFields()
    onBack()
  }, [form, onBack, resetCreateJobMutation, resetSaveDatasourceMutation])

  /**
   ** Get agent status to show error modal if agent is not active
   ** If using the runner, we don't need to check agent status
   */
  const { executeFunction: loadAgentStatus, loading: isLoadingAgentStatus } = useLazyAgentStatus()
  const agentEnabled = useIsGXAgentEnabled()

  const [isAgentErrorModalVisible, setIsAgentErrorModalVisible] = useState(false)

  const onPage1Continue = useCallback(async () => {
    const formValidationResult = await form
      .validateFields()
      .then((values: Record<string, unknown>) => values)
      .catch((errorInfo: { errorFields: unknown[] }) => errorInfo)

    // annoying that AntD doesn't expose type of errorInfo
    if ("errorFields" in formValidationResult) {
      return // nothing to do; validateFields will have already rendered error messages on form fields
    }

    // Check if agent is connected before running job if using an agent, otherwise create job if using the runner

    const runJobWithRunner = async () => {
      setJobIsPending(true)
      setErrorMessage(null)
      await createJob()
    }
    const runJobWithAgent = async () => {
      const res = await loadAgentStatus()
      const isAgentConnected = res.data?.agentStatus.active
      if (isAgentConnected) {
        setJobIsPending(true)
        setErrorMessage(null)
        await createJob()
      } else {
        setIsAgentErrorModalVisible(true)
      }
    }
    const runJobFn = agentEnabled ? runJobWithAgent : runJobWithRunner
    runJobFn()
  }, [form, createJob, loadAgentStatus, agentEnabled])

  useEffect(() => {
    if (selectedTableNames.length || !schemaChangeDetection) {
      setPage2Error(undefined)
    }
  }, [selectedTableNames, schemaChangeDetection])

  const onPage2Submit = useCallback(async () => {
    if (!(selectedTableNames.length > 0)) {
      setPage2Error("Select at least one table")
      return
    }
    if (selectedTableNames.length > AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT && selectedDataQualityIssues.length) {
      setPage2Error(AUTOGENERATE_EXPECTATIONS_LIMIT_ERROR)
      return
    }
    await saveDatasource({
      variables: {
        input: {
          config: stringify(data),
          tableNames: selectedTableNames,
          ...(isColumnChangeDetectionEnabled && { autogenerateExpectations: schemaChangeDetection }),
          ...((isColumnChangeDetectionEnabled || isVolumeChangeDetectionEnabled) && { selectedDataQualityIssues }),
        },
      },
    })
  }, [
    selectedTableNames,
    saveDatasource,
    data,
    isColumnChangeDetectionEnabled,
    schemaChangeDetection,
    isVolumeChangeDetectionEnabled,
    selectedDataQualityIssues,
  ])

  useAssetCreationJobStatus({
    jobId: jobIsPending ? testConnectionData?.createTestDatasourceJob?.jobId : undefined,
    onError: handleTestConnectionMutationError,
    onComplete: (job) => {
      // we know this is already true if the feature flag is turned on, but it's hard to explain that to the compiler
      if (job?.tableNames?.length && job.tableNames.length > 0) {
        setTableNames(job.tableNames as string[]) // we need to fix this on the server
      }
      setJobIsPending(false)
      resetCreateJobMutation()
      setPage(2)
    },
  })

  if (!agentEnabled && isEnableAgentRequestPending) {
    return (
      <ScrollableFlex vertical gap="middle">
        <RequestAgentAlert />
      </ScrollableFlex>
    )
  }

  const selectAutoGenerateExpectations = isColumnChangeDetectionEnabled ? (
    <SelectAutogenerateExpectations value={schemaChangeDetection} setValue={setSchemaChangeDetection} />
  ) : undefined
  const schemaCheck = { value: schemaChangeDetection, setValue: setSchemaChangeDetection }
  const volumeCheck = { value: volumeChangeDetection, setValue: setVolumeChangeDetection }
  const selectDataQualityChecks = isVolumeChangeDetectionEnabled ? (
    <SelectDataQualityChecks schema={schemaCheck} volume={volumeCheck} />
  ) : undefined

  return (
    <>
      <ScrollableFlex vertical gap="middle">
        {page === 1 && (
          <Flex vertical gap="large">
            {!agentEnabled && <RequestAgentAlert />}
            <LogoAndLinkHeader dataSourceType={dataSourceType} text="Connect" secondaryText="schema as a Data Source" />
          </Flex>
        )}
        {page === 2 && (
          <AddAssetDrawerHeader
            dataSourceType={dataSourceType}
            dataSourceName={data.name as string}
            dataSourceAddress={dataSourceAddress}
          />
        )}
        {page === 1 && (
          <Form form={form} layout="vertical">
            <JsonForm
              jsonSchema={jsonSchema}
              uiSchema={page1UISchema}
              uiSchemaRegistryEntries={getUISchemaRegistryEntries(dataSourceType)}
              customRendererRegistryEntries={[GroupRendererRegistryEntry]}
              data={data}
              updateData={onChange}
            />
            {errorMessage && (
              <AlertBanner
                message={errorMessage.message ?? "There was an error connecting to your Data Source."}
                description={errorMessage.description}
                type={errorMessage.type}
              />
            )}
          </Form>
        )}
        {page === 2 && tableNames && (
          <>
            <div style={{ flexGrow: 0.95, overflowY: "scroll" }}>
              <SelectTableNames tableNames={tableNames} setSelectedTableNames={setSelectedTableNames} />
            </div>
            {selectDataQualityChecks ?? selectAutoGenerateExpectations}
            {page2Error && (
              <AlertBanner message="There was an error saving your Data Source." description={page2Error} />
            )}
          </>
        )}
      </ScrollableFlex>
      <AssetDrawerFooter
        page={page}
        setPage={setPage}
        isLoading={jobIsPending || saveInProgress || isLoadingAgentStatus}
        onPage1Continue={onPage1Continue}
        onPage2Submit={onPage2Submit}
        onClose={onClose}
        tableNames={selectedTableNames}
      />
      <AgentNotConnectedModal isVisible={isAgentErrorModalVisible} setIsVisible={setIsAgentErrorModalVisible} />
    </>
  )
}
