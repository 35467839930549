/* eslint-disable react-refresh/only-export-components */ // FIXME
import { useEffect, useMemo, useState } from "react"
import { PickNewOrExistingDataSource } from "src/DataAssets/connect-to-data/PickNewOrExistingDataSource"
import { AddAssetToNewDataSource } from "src/DataAssets/connect-to-data/AddAssetToNewDataSource"
import { AddAssetToExistingDataSource } from "src/DataAssets/connect-to-data/AddAssetToExistingDataSource"
import { Drawer } from "src/ui/Drawer/Drawer"
import { DatasourceWithRunsFragment } from "src/api/graphql/graphql-operations"
import { DrawerStyles } from "antd/es/drawer/DrawerPanel"
import { SupportedDataSource, defaultValues } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { Heading2 } from "src/ui/typography/Text/Text"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import {
  ConnectToDataSourceContainer,
  DummyLoad,
  EmptyStep,
  EnterCredentialsStep,
  SelectAssetsStep,
  type ConnectToDataSourceConfig,
} from "src/DataAssets/ConnectToDataSource"

export type DataSourceChoice =
  | {
      createNew: true
      dataSourceType?: SupportedDataSource
    }
  | {
      createNew: false
      dataSourceType: SupportedDataSource
      dataSource: DatasourceWithRunsFragment
    }

type Props = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  dataSourceChoiceFromEmptyState?: DataSourceChoice
}

export const DataSourceCrudDrawerStyles: DrawerStyles = {
  body: { padding: "0px", display: "flex", flexDirection: "column", height: "100%", width: "100%" },
}

export function NewAssetDrawer({ isOpen, setIsOpen, dataSourceChoiceFromEmptyState }: Props) {
  const stepDrivenDataSourceAndAssetCreation = useIsFeatureEnabled("stepDrivenDataSourceAndAssetCreation")
  const [dataSourceChoice, setDataSourceChoice] = useState<DataSourceChoice | undefined>(
    () => dataSourceChoiceFromEmptyState,
  )
  const [createNew, setCreateNew] = useState<boolean>(false)
  const [dataSourceType, setDataSourceType] = useState<SupportedDataSource | undefined>()
  const [dataSource, setDataSource] = useState<DatasourceWithRunsFragment | undefined>()

  const handleDataSourceChoice = (choice: DataSourceChoice) => {
    setDataSourceChoice(choice) // retain prior dataSourceChoice functionality
    setCreateNew(choice.createNew)
    setDataSourceType(choice.dataSourceType)
    setDataSource(choice.createNew === false ? choice.dataSource : undefined)
  }

  const resetDataSourceChoice = () => {
    setDataSourceChoice(undefined) // retain prior dataSourceChoice functionality
    setCreateNew(false)
    setDataSourceType(undefined)
    setDataSource(undefined)
  }

  useEffect(() => {
    if (dataSourceChoiceFromEmptyState) {
      setDataSourceChoice(dataSourceChoiceFromEmptyState)
    }
  }, [dataSourceChoiceFromEmptyState])

  const selectedDataSource = dataSourceChoice?.dataSourceType
  const isNewDataAsset = dataSourceChoice?.createNew

  const dataSourceConfig: ConnectToDataSourceConfig = useMemo(
    () => ({
      dataSourceType,
      dataSource,
      progressBar: true,
      steps: [
        {
          title: "Enter Credentials",
          bodyComponent: createNew ? EnterCredentialsStep : EmptyStep,
          footerButtons: {
            prev: {
              label: "Back",
              customAction: () => {
                resetDataSourceChoice()
              },
            },
            next: "Next",
          },
        },
        {
          title: "Select Assets",
          bodyComponent: SelectAssetsStep,
          footerButtons: {
            prev: {
              label: "Back",
              customAction: (nextStep: number) => {
                if (nextStep < 0) {
                  if (!createNew) {
                    resetDataSourceChoice()
                  } else {
                    resetDataSourceChoice()
                    setDataSourceChoice({ createNew: true })
                    setCreateNew(true)
                  }
                }
              },
            },
            next: "Add Assets",
          },
        },
        {
          title: "Dummy Load",
          bodyComponent: DummyLoad,
          footerButtons: {
            prev: "Back",
            next: "Next",
          },
        },
      ],
    }),
    [createNew, dataSource, dataSourceType],
  )

  return isOpen ? (
    <Drawer
      styles={DataSourceCrudDrawerStyles}
      open
      onClose={() => {
        setIsOpen(false)
        setDataSourceChoice(undefined)
      }}
      title={<Heading2>{createNew ? "Connect to a Data Source" : "Add a Data Asset"}</Heading2>}
    >
      <>
        {!selectedDataSource && <PickNewOrExistingDataSource onChoice={handleDataSourceChoice} />}
        {stepDrivenDataSourceAndAssetCreation ? (
          selectedDataSource && <ConnectToDataSourceContainer config={dataSourceConfig} />
        ) : (
          <>
            {dataSourceChoice?.createNew === false && (
              <AddAssetToExistingDataSource
                onFinish={() => setIsOpen(false)}
                onBack={() => setDataSourceChoice(undefined)}
                dataSource={dataSourceChoice.dataSource}
                dataSourceType={dataSourceChoice.dataSourceType}
              />
            )}
            {isNewDataAsset && selectedDataSource && (
              <AddAssetToNewDataSource
                dataSourceType={selectedDataSource}
                onBack={() => {
                  setDataSourceChoice({ createNew: true })
                  setCreateNew(true)
                }}
                onFinish={() => setIsOpen(false)}
                data={defaultValues[selectedDataSource]}
              />
            )}
          </>
        )}
      </>
    </Drawer>
  ) : null
}
