import { useQuery } from "@apollo/client"
import { graphql } from "src/api/graphql/gql"
import { useEffect, useState } from "react"
import { JobType, mapJobtoUnionType } from "src/GXAgent/JobUnion"

export const GetJobsUseRefetchOnJobCompleteDocument = graphql(`
  query GetJobs_useRefetchOnJobComplete {
    jobs {
      id
      __typename
      status
      sourceResources {
        entityId
        entityType
      }
      timeQueued
    }
  }
`)

export const useRefetchOnJobComplete = (
  { jobType, refetch }: { jobType: JobType; refetch: () => void },
  refreshTrigger?: string,
) => {
  /**
   * This hook is used to trigger a refetch and query when the latest job of a given type completes
   *
   * @jobType - The type of job to listen for
   * @refetch - The function to call when the job completes
   * @refreshTrigger - A general trigger for refreshing the effect
   */

  // Trigger a refetch and query when the latest job of a given type completes
  const [lastJobIDThatTriggeredRefetch, setLastJobIDThatTriggeredRefetch] = useState<string | null>(null)
  const [lastRefreshTrigger, setLastRefreshTrigger] = useState<string | undefined>(() => refreshTrigger)
  const { data } = useQuery(GetJobsUseRefetchOnJobCompleteDocument)
  const latestCompletedJob = data?.jobs
    .map(mapJobtoUnionType)
    ?.sort((a, b) => new Date(b.timeQueued).getTime() - new Date(a.timeQueued).getTime())
    ?.find((job) => job.jobType === jobType && job.status === "complete")

  useEffect(() => {
    /*
     * If the refreshTrigger changes, we need to refetch the data
     */
    if (refreshTrigger !== lastRefreshTrigger) {
      refetch()
      setLastRefreshTrigger(refreshTrigger)
    }
  }, [lastRefreshTrigger, refetch, refreshTrigger])

  useEffect(() => {
    /*
     * If the latest completed job is different from the last job that triggered the refetch, we need to refetch the data
     */
    if (latestCompletedJob && latestCompletedJob.id !== lastJobIDThatTriggeredRefetch) {
      refetch()
      setLastJobIDThatTriggeredRefetch(latestCompletedJob.id)
    }
  }, [lastJobIDThatTriggeredRefetch, latestCompletedJob, refetch])
}
