import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { SelectTableNames } from "src/DataAssets/connect-to-data/select-table-names/SelectTableNames"
import { getMatchingAssets } from "src/DataAssets/connect-to-data/select-table-names/tableNameUtils"
import ConnectToDataSourceContext from "src/DataAssets/ConnectToDataSource/ConnectToDataSourceContext"
import {
  CreateListTableNamesJobDocument,
  ListTableNamesMutationErrorProps,
} from "src/DataAssets/connect-to-data/AddAssetToExistingDataSource"
import { ApolloError, useMutation } from "@apollo/client"
import { AlertProps, Flex, Spin } from "antd"
import { useAssetCreationJobStatus } from "src/DataAssets/connect-to-data/useAssetCreationJobStatus"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"
import { useLazyAgentStatus } from "src/common/hooks/useAgentStatus"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import { LoadingOutlined } from "@ant-design/icons"
import { theme } from "src/ui/themes/theme"
import { AlertBanner } from "src/ui/Alert"
import { AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT, AUTOGENERATE_EXPECTATIONS_LIMIT_ERROR } from "src/common/config"
import { StepComponentWithSlot } from "src/DataAssets/ConnectToDataSource/types"
import { StepContainer } from ".."
import { isEmpty, difference } from "lodash-es"

export function SelectAssetsStep({ children }: StepComponentWithSlot) {
  // Context state
  const { dataSource, selectedTables, setSelectedTables, allTableNames, setAllTableNames, setFilteredTables } =
    useContext(ConnectToDataSourceContext)

  // Component state
  const matchingTables = getMatchingAssets(allTableNames ?? [], dataSource?.assets ?? [])
  const [errorBanner, setErrorBanner] = useState<null | AlertProps>(null)
  const [tableJobPending, setTableJobPending] = useState<boolean>(false)

  // Agent/Runner status and modal state
  const isGXAgentEnabled = useIsGXAgentEnabled()
  const { executeFunction: loadAgentStatus } = useLazyAgentStatus()
  const [isAgentErrorModalVisible, setIsAgentErrorModalVisible] = useState(false)

  // Fetch tables
  const [createJob, { data, reset }] = useMutation(CreateListTableNamesJobDocument, {
    onError: (err: ApolloError) => handleListTableNamesError({ description: err.message, message: err.name }),
  })

  // Handle table fetch errors
  const handleListTableNamesError: ({ description, message, type }: ListTableNamesMutationErrorProps) => void =
    useCallback(
      ({ description, message, type }) => {
        setErrorBanner({ description, message: message ?? null, type: type ?? "error" })
        setTableJobPending(false)
        reset()
      },
      [reset],
    )

  // Poll table fetch job
  useAssetCreationJobStatus({
    jobId: tableJobPending ? data?.createListTableNamesJob?.jobId : undefined,
    onError: handleListTableNamesError,
    onComplete: (job) => {
      if (job?.tableNames?.length && job.tableNames.length > 0) {
        // TODO: the comment on the next line was inherited from old components; investigate
        setAllTableNames(job.tableNames as string[]) // we need to fix this on the server
      }
      setTableJobPending(false)
      reset()
    },
  })

  // Agent/runner-aware table fetch wrapper
  const getTableNames = useCallback(async () => {
    if (isGXAgentEnabled) {
      const res = await loadAgentStatus()
      const isAgentConnected = res.data?.agentStatus.active
      if (!isAgentConnected) {
        setIsAgentErrorModalVisible(true)
        return
      }
    }

    setTableJobPending(true)
    setErrorBanner(null)
    dataSource && (await createJob({ variables: { datasourceId: dataSource.id } }))
  }, [createJob, isGXAgentEnabled, loadAgentStatus, dataSource])

  // Fetch tables on load
  useEffect(() => {
    if (isEmpty(allTableNames)) {
      getTableNames()
    } else {
      setAllTableNames(allTableNames)
    }
  }, [allTableNames, getTableNames, setAllTableNames])

  // Watch for > AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT & set error banner
  useEffect(() => {
    const tooManyTablesSelected = (selectedTables?.length ?? 0) > AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT && !errorBanner
    const deselectedTablesToRemoveError =
      (selectedTables?.length ?? 0) <= AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT && errorBanner

    if (tooManyTablesSelected) {
      setErrorBanner({
        message: "Unable to proceed",
        description: AUTOGENERATE_EXPECTATIONS_LIMIT_ERROR,
      })
    } else if (deselectedTablesToRemoveError) {
      setErrorBanner(null)
    }
  }, [errorBanner, matchingTables, selectedTables, setSelectedTables])

  const selectedTableDifference = useMemo(
    () => difference(selectedTables, matchingTables),
    [matchingTables, selectedTables],
  )

  const handleNext = useCallback(async () => {
    Promise.resolve(setFilteredTables(selectedTableDifference))
  }, [selectedTableDifference, setFilteredTables])

  const noNewTablesSelected = useMemo(() => isEmpty(selectedTableDifference), [selectedTableDifference])

  return children(
    {
      loading: tableJobPending,
      disableProgress: noNewTablesSelected,
      next: handleNext,
    },
    <StepContainer>
      {tableJobPending && (
        <Flex gap="small" align="center">
          <Spin
            indicator={
              <LoadingOutlined
                spin
                style={{
                  fontSize: theme.typography.fontSize.large,
                  color: theme.colors.primaryColors.gxAccentMedium,
                }}
              />
            }
          />
          <p>Loading tables</p>
        </Flex>
      )}
      {!isEmpty(allTableNames) && !tableJobPending && (
        <SelectTableNames
          tableNames={allTableNames}
          preselectedTableNames={selectedTables}
          setSelectedTableNames={setSelectedTables}
          matchingTableNames={matchingTables}
        />
      )}
      {errorBanner && (
        <AlertBanner
          message={errorBanner.message ?? "There was an error connecting to Data Source."}
          description={errorBanner.description}
          type={errorBanner.type}
        />
      )}
      <AgentNotConnectedModal isVisible={isAgentErrorModalVisible} setIsVisible={setIsAgentErrorModalVisible} />
    </StepContainer>,
  )
}
