import { Steps as AntdSteps, StepsProps } from "antd"
import styled, { css } from "styled-components"

const StyledSteps = styled(AntdSteps)<StepsProps>`
  ${({ theme }) => css`
    &:where(.css-dev-only-do-not-override-1nz0vha).ant-steps .ant-steps-item-process .ant-steps-item-icon {
      background-color: ${theme.colors.primaryColors.gxAccentMedium};
      border-color: ${theme.colors.primaryColors.gxAccentMedium};
    }
    &:where(.css-dev-only-do-not-override-1nz0vha).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
      background-color: ${theme.colors.primaryColors.gxAccentBackground};
      border-color: ${theme.colors.primaryColors.gxAccentMedium};
    }
    &:where(.css-dev-only-do-not-override-1nz0vha).ant-steps
      .ant-steps-item-finish
      .ant-steps-item-icon
      > .ant-steps-icon {
      color: ${theme.colors.primaryColors.gxAccentMedium};
    }
    &:where(.css-dev-only-do-not-override-1nz0vha).ant-steps
      .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: ${theme.colors.primaryColors.gxAccentMedium};
    }
  `}
`

export const Steps = (props: StepsProps) => {
  return <StyledSteps {...props} />
}
