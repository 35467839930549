import { useState } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Col, Row, Card, Descriptions, Space } from "antd"

import { graphql } from "src/api/graphql/gql"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { getDataAssetBreadcrumbs } from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { DataAssetTabs } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { MetricsTable } from "src/DataAssets/AssetDetails/MetricsTable"
import { SimpleNewExpectationDrawer } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer/SimpleNewExpectationDrawer"
import { useAssetSummaryDescriptionItems } from "src/DataAssets/AssetDetails/useAssetSummaryDescriptionItems"
import { MainContent } from "src/layout/MainContent"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { PageHeader } from "src/ui/PageHeader/PageHeader"
import { Heading3 } from "src/ui/typography/Text/Text"
import { useIsDemoData } from "src/common/hooks/useIsDemoData"
import { DataAssetOverviewAlert } from "src/Alerts/DemoData/DataAssetOverviewAlert"
import { useDemoDataAssetName } from "src/common/hooks/useDemoDataAssetName"

export const OverviewTab_DataAssetDocument = graphql(`
  query OverviewTab_DataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      name
      datasourceV2 {
        id
        ...DataAssetBreadcrumbs_DataSource
      }
      ...AssetSummaryDescriptionItems_DataAsset
      ...UseIsDemoData_DataAsset
      ...UseDemoDataAssetName_DataAsset
      ...UseDataSourceName_DataAsset
    }
  }
`)

export function OverviewTab() {
  const { assetId } = useParams<Record<"assetId", string>>()
  const dataAssetId = decodeURIComponent(assetId ?? "")

  const { data } = useQuery(OverviewTab_DataAssetDocument, {
    variables: { id: dataAssetId },
    skip: !dataAssetId,
  })

  const isEditor = useRequireRole("EDITOR")
  const columnChangeDetectionEnabled = useIsFeatureEnabled("columnChangeDetection")
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const isDemoData = useIsDemoData(data?.dataAsset)
  const assetName = useDemoDataAssetName(data?.dataAsset)

  const headerContent: HeaderTypes = {
    title: data?.dataAsset?.name ?? "",
    rootPath: "data-assets",
    navigateBackTo: "/data-assets",
    ...(isEditor && {
      rightActions: {
        ctaButton: {
          type: "primary",
          icon: "plus",
          children: "New Expectation",
          disabled: !dataAssetId,
          onClick: () => setIsDrawerOpen(true),
        },
      },
    }),
    customBreadcrumbs: getDataAssetBreadcrumbs({
      dataSource: data?.dataAsset?.datasourceV2,
      tooltipPlacement: "right",
    }),
    footer: <DataAssetTabs currentTab={columnChangeDetectionEnabled ? "metrics" : "overview"} />,
  }
  const descriptionItems = useAssetSummaryDescriptionItems(data?.dataAsset)

  return (
    <PageHeader headerContent={headerContent}>
      <MainContent>
        {dataAssetId && (
          <SimpleNewExpectationDrawer
            dataAssetId={dataAssetId}
            open={isDrawerOpen}
            close={() => setIsDrawerOpen(false)}
          />
        )}
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={6}>
            <Card title={<Heading3>Data Asset Information</Heading3>}>
              <Descriptions column={1} layout="vertical" items={descriptionItems} />
            </Card>
          </Col>
          <Col xs={24} xl={18} className="sentry-mask">
            <Space direction="vertical" size="large" style={{ display: "flex" }}>
              {isDemoData && <DataAssetOverviewAlert assetName={assetName} />}
              {dataAssetId && <MetricsTable assetId={dataAssetId} />}
            </Space>
          </Col>
        </Row>
      </MainContent>
    </PageHeader>
  )
}
