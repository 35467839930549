import { parseJSONStringConfig } from "src/DataAssets/AssetDetails/utils.tsx"
import { demoDataSourceConfig } from "src/DataAssets/connect-to-data/schemas/data-source-schemas.ts"
import { graphql } from "src/api/graphql/gql"
import { FragmentType, unmaskFragment } from "src/api/graphql"

export const UseIsDemoData_DataAssetFragmentDoc = graphql(`
  fragment UseIsDemoData_DataAsset on AssetRef {
    datasourceV2 {
      id
      config
    }
  }
`)

type FragmentData = FragmentType<typeof UseIsDemoData_DataAssetFragmentDoc> | null | undefined

export function useIsDemoData(asset: FragmentData): boolean {
  if (!asset) return false
  const dataAsset = unmaskFragment(UseIsDemoData_DataAssetFragmentDoc, asset)
  const config = dataAsset.datasourceV2?.config && parseJSONStringConfig(dataAsset.datasourceV2?.config)

  return (
    !!config &&
    typeof config === "object" &&
    "connection_string" in config &&
    config.connection_string === demoDataSourceConfig.connectionString
  )
}
