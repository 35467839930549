import { ReactNode } from "react"
import { useCurrentUser } from "src/common/hooks/useCurrentUser"
import { ApolloAuth0AuthenticatedProvider } from "src/api/ApolloAuthenticatedProvider"
import { Navigate } from "react-router-dom"
import { LoadingState } from "src/ui/LoadingState"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"

type AuthWrapperProps = {
  children: ReactNode
  redirectPath?: string
}

export const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const { isAuthenticated, isLoading } = useCurrentUser()
  const redirectPath = useIsFeatureEnabled("newSignUpFlowEnabled") ? "/start" : "/login"

  if (isLoading) {
    return <LoadingState loading />
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} />
  }

  return <ApolloAuth0AuthenticatedProvider>{children}</ApolloAuth0AuthenticatedProvider>
}
