import { Button, Flex } from "antd"
import styled from "styled-components"

import { Image } from "src/ui/Image"
import { BodyNormal, Heading1 } from "src/ui/typography/Text/Text"
import { theme } from "src/ui/themes/theme"
import { Background } from "src/ui/OnboardingFlow/styles"
import { useCurrentUser } from "src/common/hooks/useCurrentUser"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useUrlParams } from "src/common/utils/url-params"

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const LoginModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 328px; /* 328px + 36px padding on both sides is 400px (desired width of the modal) */
  padding: 60px 36px;
  background-color: #fff;
  box-shadow: 0 12px 40px rgb(0 0 0 / 12%);
  border-radius: 16px;
  border-top: 1px solid #e9e9e9;
`

const PaddedFlex = styled(Flex)`
  width: 100%;
  margin-top: ${theme.spacing.horizontal.m};
`

const logoStyle = { height: "50px", width: "200px" }

export const ERROR_MESSAGE = "Login failed. Please check your credentials and try again."

export const LandingPage = () => {
  const [{ error }] = useUrlParams({ error: "" })
  const navigate = useNavigate()
  const { loginWithRedirect, isAuthenticated } = useCurrentUser()

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated, navigate])

  return (
    <Background>
      <MainContainer>
        <LoginModal>
          <Image
            aria-label="Great Expectations Logo"
            type="geLogoWithDarkText"
            svgProps={logoStyle}
            style={logoStyle}
          />
          <PaddedFlex vertical gap={theme.spacing.horizontal.m}>
            <Heading1>Welcome to GX Cloud</Heading1>

            <Button
              style={{ boxShadow: "none" }}
              size="large"
              type="primary"
              onClick={() => {
                loginWithRedirect({ appState: { returnTo: "/" } })
              }}
            >
              Log in
            </Button>
            <Flex vertical gap="small">
              <BodyNormal>Don&apos;t have an account?</BodyNormal>
              <Button
                style={{ boxShadow: "none" }}
                size="large"
                onClick={() => {
                  navigate("/signup")
                }}
              >
                Sign up
              </Button>
            </Flex>
            {error && <BodyNormal $color="gxError">{ERROR_MESSAGE}</BodyNormal>}
          </PaddedFlex>
        </LoginModal>
      </MainContainer>
    </Background>
  )
}
