import { Icon } from "src/ui/Icon/Icon"
import { useEffect, useState } from "react"
import { message, notification } from "antd"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import { MESSAGE_DURATION_SECONDS, NOTIFICATION_WITH_LINK_DURATION_SECONDS } from "src/common/config"
import { SplitterOptionsInput } from "src/api/graphql/graphql"
import { BatchDefinitionForValidationRunPopOver } from "src/DataAssets/AssetDetails/Expectations/RunValidationButton/BatchDefinitionForValidationRunPopOver/BatchDefinitionForValidationRunPopOver"
import { SupportEmailLink } from "src/ui/SupportEmailLink/SupportEmailLink"
import { Button } from "src/ui/Button/Button"
import { Link } from "src/ui/typography/Link/Link.tsx"
import { useAhaSalesDemoModal } from "src/DataAssets/AssetDetails/Expectations/RunValidationButton/useAhaSalesDemoModal"
import { isWholeAssetSplitterType } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import {
  RunValidationButtonAssetRefFragmentDocument,
  RunValidationButtonJobsFragmentDocument,
} from "src/DataAssets/AssetDetails/Expectations/RunValidationButton/RunValidationButton"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking"
import { nonNull } from "src/common/utils/nonNull"

export interface RunValidationButtonUIProps {
  // loading makes the button spin if true
  loading: boolean
  // disabled makes the button unclickable if true
  disabled: boolean
  // onClick is called when the button is clicked
  onClick: (splitterOptions?: SplitterOptionsInput) => Promise<void>
  // assetData specifies GQL data dependencies
  assetData?: FragmentType<typeof RunValidationButtonAssetRefFragmentDocument> | null
  // jobData specifies GQL data dependencies
  jobData?: FragmentType<typeof RunValidationButtonJobsFragmentDocument> | null
  // isEditor adjusts behavior for a given user role
  isEditor: boolean
  // onJobComplete is called when the corresponding job is complete
  onJobComplete: () => void
}

export const VALIDATE_SUCCESS_TEXT = "Data Asset validation complete."
export const VALIDATE_ERROR_TEXT = "Unable to validate Data Asset."
export const SERVER_ERROR_TITLE = "Server Error"
export const VALIDATE_BUTTON_TEXT = "Validate"
export const UNABLE_TO_VALIDATE_SERVER_ERROR_DESCRIPTION = (
  <>
    We were unable to validate your Asset. If the problem persists, contact <SupportEmailLink />
  </>
)
export const VALIDATE_ERROR_DESCRIPTION = (
  <>
    See{" "}
    <Link to="/logs" strong>
      logs
    </Link>{" "}
    for details.
  </>
)

export type ValidationStatus = "inProgress" | "complete" | "error" | "inactive" | "agentNotConnected"

export function RunValidationButtonUI(props: RunValidationButtonUIProps) {
  const {
    loading: _loading,
    disabled: _disabled,
    onClick,
    assetData: maskedAssetData,
    jobData: maskedJobData,
    isEditor,
    onJobComplete,
  } = props
  const assetData = unmaskFragment(RunValidationButtonAssetRefFragmentDocument, maskedAssetData)
  const jobData = unmaskFragment(RunValidationButtonJobsFragmentDocument, maskedJobData)
  const checkpointIDs: string[] = assetData?.checkpoints.filter(nonNull).map((c) => c.id) ?? []

  const job = jobData?.jobs?.find(
    (job) =>
      (job.jobType === "RUN_CHECKPOINT_REQUEST" || job.jobType === "RUN_WINDOW_CHECKPOINT_RECEIVED") &&
      job.sourceResources.find((sr) => sr.entityType === "Checkpoint" && checkpointIDs.includes(sr.entityId)),
  )

  const jobStatus = job?.status
  const jobErrorAgentNotConnected: boolean = Boolean(job?.jobError?.errorStackTrace?.includes("No Agent is connected"))

  const hasBatchDefinition = !isWholeAssetSplitterType(assetData?.splitter?.__typename)

  const [validationStatus, setValidationStatus] = useState<ValidationStatus>("inactive")
  const [notificationApi, notificationContextHolder] = notification.useNotification()
  const loading = _loading || validationStatus === "inProgress"
  const disabled = _disabled || loading || !isEditor
  const [shouldShowDoneToast, setShouldShowDoneToast] = useState(false)
  const { openModalAfterDelay } = useAhaSalesDemoModal()

  const doValidate = async () => {
    setValidationStatus("inProgress")

    try {
      await onClick()
      message.loading("Validation started", MESSAGE_DURATION_SECONDS)
    } catch (e) {
      console.error(e)
      notificationApi.error({
        message: SERVER_ERROR_TITLE,
        description: UNABLE_TO_VALIDATE_SERVER_ERROR_DESCRIPTION,
        duration: 0,
        placement: "top",
      })
      setValidationStatus("error")
    }
  }

  useEffect(() => {
    switch (jobStatus) {
      case "complete":
        if (shouldShowDoneToast) {
          notificationApi.success({
            message: VALIDATE_SUCCESS_TEXT,
            duration: NOTIFICATION_WITH_LINK_DURATION_SECONDS,
            placement: "top",
          })
        }
        setValidationStatus("complete")
        onJobComplete()
        setShouldShowDoneToast(false)
        openModalAfterDelay()
        break
      case "error":
        if (jobErrorAgentNotConnected) {
          setValidationStatus("agentNotConnected")
        } else {
          setValidationStatus("error")
          if (shouldShowDoneToast) {
            notificationApi.error({
              message: VALIDATE_ERROR_TEXT,
              description: VALIDATE_ERROR_DESCRIPTION,
              duration: 0,
              placement: "top",
            })
          }
          setShouldShowDoneToast(false)
        }
        break
      case "inProgress":
      case "queued":
        setValidationStatus("inProgress")
        setShouldShowDoneToast(true)
        break
      case undefined:
        setValidationStatus("inactive")
        break
      default:
        break
    }
  }, [jobStatus, onJobComplete, notificationApi, shouldShowDoneToast, openModalAfterDelay, jobErrorAgentNotConnected])

  const validateBtn = (
    <Button
      type="primary"
      icon={<Icon name="play" size="14px" />}
      onClick={
        // onClick is configured on the pop-over when a batch definition is used
        hasBatchDefinition ? undefined : doValidate
      }
      loading={loading}
      disabled={disabled}
    >
      {VALIDATE_BUTTON_TEXT}
    </Button>
  )

  return (
    <>
      {notificationContextHolder}
      <AgentNotConnectedModal
        isVisible={validationStatus === "agentNotConnected"}
        setIsVisible={() => setValidationStatus("inactive")}
      />
      {hasBatchDefinition ? (
        <BatchDefinitionForValidationRunPopOver
          onClose={() => {
            setValidationStatus("inactive")
          }}
          onSave={doValidate}
        >
          {validateBtn}
        </BatchDefinitionForValidationRunPopOver>
      ) : (
        validateBtn
      )}
    </>
  )
}
