/**
 * This overrides the jsonforms-antd-renderers version of the NumericSliderControl
 * and allows for the custom configuration of column widths of the produced
 * slider. Opted for this approach over modifying the core library to avoid
 * the additional overhead of making these values configurable by default.
 */
import {
  and,
  isIntegerControl,
  isNumberControl,
  JsonSchema,
  or,
  rankWith,
  schemaMatches,
  scopeEndsWith,
  type ControlProps as JSFControlProps,
  type JsonFormsRendererRegistryEntry,
} from "@jsonforms/core"
import { Col, Form, Row } from "antd"
import type { Rule } from "antd/es/form"
import { InputNumber, Slider, ControlUISchema } from "@great-expectations/jsonforms-antd-renderers"
import { withJsonFormsControlProps } from "@jsonforms/react"

type ControlProps = Omit<JSFControlProps, "uischema"> & {
  uischema: ControlUISchema<unknown> | JSFControlProps["uischema"]
}

export const NumericSliderRegistryEntry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(
    100,
    and(
      or(isNumberControl, isIntegerControl),
      schemaMatches((schema: JsonSchema) => schema.minimum !== undefined && schema.maximum !== undefined),
      or(scopeEndsWith("#/properties/percent"), scopeEndsWith("#/properties/mostly")),
    ),
  ),
  renderer: withJsonFormsControlProps(NumericSliderControl),
}

export function NumericSliderControl(props: ControlProps) {
  if (!props.visible) return null

  const initialValue = typeof props.schema.default === "number" ? props.schema.default : props.schema.minimum

  const rules: Rule[] = [{ required: props.required, message: `${props.label} is required` }]

  const formItemProps = "formItemProps" in props.uischema ? props.uischema.formItemProps : {}

  return (
    <Form.Item
      label={props.label}
      id={props.id}
      name={props.path}
      required={props.required}
      initialValue={initialValue}
      rules={rules}
      validateTrigger={["onBlur"]}
      {...formItemProps}
    >
      <Row>
        <Col span={14}>{Slider({ ...props })}</Col>
        <Col span={8}>{InputNumber({ ...props })}</Col>
      </Row>
    </Form.Item>
  )
}

export const NumericSliderRenderer = withJsonFormsControlProps(NumericSliderControl)
