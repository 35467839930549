import { Auth0ContextInterface, LogoutOptions, useAuth0, User as Auth0User } from "@auth0/auth0-react"
import getAuth0Config from "src/authentication/getAuth0Config"
import { getAuth0RefreshToken } from "src/authentication/tokens"
import { removeLocalStorageItem } from "src/common/utils/local-storage"
import posthog from "posthog-js"
import { Sentry } from "src/observability/sentry/sentry"
import { REACT_APP_APP_URL, REACT_APP_API_URL } from "src/common/env"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"

export interface GXUser extends Auth0User {
  id: string
  name: string
  email: string
  new_self_service_user: boolean
  loginCount: number
}

export type CurrentUserContext = Auth0ContextInterface<GXUser>

// useCurrentUser provides info and functionality for the authenticated user.
// It wraps `useAuth0` which should be imported in this file only (clean architecture).
function useCurrentUser(): CurrentUserContext {
  const auth0 = useAuth0<GXUser>()
  const newSignUpFlowEnabled = useIsFeatureEnabled("newSignUpFlowEnabled")
  const logoutRoute = newSignUpFlowEnabled ? "start" : "login"

  const cleanUp = () => {
    removeLocalStorageItem("auth0Token")
    posthog.reset()
    Sentry.setUser(null)
  }

  const logout = async (options?: LogoutOptions) => {
    const logoutOptions = {
      ...options,
      logoutParams: {
        ...options?.logoutParams,
        returnTo: `${window.location.origin}/${logoutRoute}`,
      },
    }

    try {
      const url = `${REACT_APP_API_URL}/oauth/revoke`
      const { clientId } = getAuth0Config()
      const token = getAuth0RefreshToken()
      if (token) {
        await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ client_id: clientId, token, user_id: auth0.user?.id }),
        })
      } else {
        console.warn("unable to get refresh token during revoke")
      }
    } catch (error) {
      console.error("Token revoke error:", error)
    } finally {
      cleanUp()
      auth0.logout(logoutOptions)
    }
  }

  if (auth0.error) {
    console.error("useCurrentUser:", "auth0:", auth0.error)
    // queryParams will store the wrong appState if not cleaned up
    removeLocalStorageItem("queryParams")
    cleanUp()
    auth0.logout({
      logoutParams: {
        returnTo: `${window.location.origin}/${logoutRoute}?error=true`,
      },
    })
  }

  return {
    ...auth0,
    user: {
      ...auth0.user,
      id: auth0.user?.[`${REACT_APP_APP_URL}/user_id`] ?? "",
      name: auth0.user?.name ?? "",
      email: auth0.user?.email ?? "",
      new_self_service_user: auth0.user?.[`${REACT_APP_APP_URL}/new_self_service_user`] ?? false,
      loginCount: auth0.user?.login_count,
    },
    logout,
  }
}

export { useCurrentUser }
